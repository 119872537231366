.strategy-search__table {
  &-main {
    padding: 8px 0px 24px 16px;
  }
  &-container {
    border: $ss-border-secondary;
    border-radius: $ss-border-radius;
    overflow: hidden;
  }
}
