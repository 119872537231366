.strategy-search__sort {
  color: $ss-c-dblue;
  background: $ss-c-gray;
  align-items: center;
  padding: 10px 100px 10px 25px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  &-spacer {
    padding: 10px 30px 10px 12px;
  }

  &-help {
    vertical-align: middle;
    padding-left: 2px;
  }

  &-item {
    cursor: pointer;
    &:not(:first-child) {
      text-align: right;
    }
  }
  &-arrow {
    vertical-align: middle;
  }
}
