@import './strategyCommon';
@import './strategySearchHeader';
@import './strategySearchInput';
@import './strategySearchSort';
@import './strategySearchTile';
@import './strategySearchItem';
@import './strategyCheckBox';
@import './strategyTooltip';
@import './strategyCollapsableItem';
@import './strategyExplorerLines';
@import './strategyTable';

.strategy-search {
  &__main {
    &-w {
      font-family: $font-main;
      margin-top: 104px;
      padding-top: 25px;
    }

    &-disclaimer {
      margin-top: 100px;
      font-size: 12px;
      line-height: 17px;
      color: $ss-c-dgray;
    }
  }

  &__body-styles {
    background-color: $ss-c-lgray;
  }

  &__spacer {
    &--left {
      padding-left: $ss-spacer;
    }
    &--right {
      padding-right: $ss-spacer;
    }
  }

  &__container {
    padding: 16px 0px;
  }

  &__card {
    background: $ss-c-white;
    margin-bottom: 16px;
    border: 1px solid $ss-c-gray;
    border-radius: $ss-border-radius;
    padding: 8px 32px 8px 16px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__col {
    display: flex;
    flex-direction: column;
    &-w {
      width: 100%;
    }

    &-item {
      color: $c-nero;
      &--long {
        width: 55%;
      }
      &--long-med {
        width: 30%;
      }

      &--medium {
        width: 20%;
        text-align: right;
      }

      &--short {
        width: 12%;
        text-align: right;
      }

      &--flp-med {
        width: 20%;
      }
      &--arrow {
        width: 5%;
        text-align: right;
      }
    }
  }
}
