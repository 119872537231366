.explorer-lines {
  &__header {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
  &__line {
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-left {
      flex: 1 1;
      border-bottom: $ss-border-primary;
      border-left: $ss-border-primary;
    }
    &-bottom {
      flex: 1 1;
      &--active {
        border-left: $ss-border-primary;
      }
    }
  }
  &__body {
    &-line {
      &--active {
        border-left: $ss-border-primary;
      }
    }
  }
}
