.im-position {
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 32px;
    background: white;
    margin-bottom: 25px;
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__account {
    &-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      &--right {
        display: flex;
        flex-direction: row;
      }
    }

    &-label {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 26px;
      min-width: 150px;
      &:first-child {
        margin-right: 20px;
      }
      &--w {
        max-width: 720px;
      }

      &--edit {
        margin-top: -5px;
        margin-left: 40px;
      }
    }

    &-key {
      display: inline-block;
      text-align: right;
      color: #676879;
      font-size: 14px;
      line-height: 22px;
      &:empty {
        background: #aeb6be;
        min-height: 10px;
        margin: 6px 0px 0px 50%;
        max-width: 48px;
      }

      &--w {
        &:empty {
          background: #aeb6be;
          min-height: 10px;
          margin: 6px 0px 0px 20%;
          max-width: 80px;
        }
      }
    }

    &-value {
      display: inline-block;
      text-align: right;
      font-size: 16px;
      line-height: 26px;

      &:empty {
        background: #aeb6be;
        opacity: 0.7;
        min-height: 10px;
        min-width: 96px;
        margin-top: 13px;
      }
    }

    &-keyname {
      display: inline-block;
      text-align: right;
      color: #676879;
      font-size: 14px;
      line-height: 22px;
    }

    &-name {
      display: inline-block;
      font-size: 18px;
      line-height: 30px;
      &:empty {
        min-height: 16px;
        max-width: 240px;
        background: #aeb6be;
      }
    }

    &-number {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;

      &:empty {
        margin-top: 14px;
        min-height: 10px;
        max-width: 96px;
        opacity: 0.7;
        background: #aeb6be;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 26px;
    padding-top: 0px;

    span {
      display: inline-block;
      margin-top: -10px;
      margin-bottom: 12px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  /* Overrides for M3 */
  &__tooltip-edit {
    font-size: 12px;
    line-height: 16px;
    height: 90px;
    width: 185px;
    box-sizing: border-box;
    svg {
      color: #2178c4;
      &:hover {
        color: #2178c4;
      }
    }
  }
}
