//--------- VARS ---------

$ss-spacer: 16px;
// colors
$ss-c-primary: #255eba;
$ss-c-white: #fff;
$ss-c-black: #000;
$ss-c-gray: #d2d2d2;
$ss-c-lgray: #f4f4f4;
$ss-c-dgray: #676879;
$ss-c-dblue: #22263f;
// borders
$ss-border-primary: 1px solid $ss-c-primary;
$ss-border-secondary: 1px solid $ss-c-gray;
$ss-border-radius: 6px;

//--------- MIXINGS ---------

@mixin hover-slide {
  cursor: pointer;
  user-select: none;
  position: relative;
  left: 0px;
  transition: left 300ms;
  &:hover {
    left: 10px;
    @content;
  }
}

@mixin focus-gradient {
  background-image: linear-gradient(90deg, #fff 0%, #f4f4f4 80%, #e9e9e9 100%);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size 300ms;
  &:focus {
    background-size: 0% 100%;
    @content;
  }
}

//--------- GLOBAL ---------

.strategy-search {
  &__h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
    color: $ss-c-dblue;
  }
  &__h3 {
    font-size: 22px;
    line-height: 26px;
    color: $ss-c-dblue;
  }
  &__h4 {
    font-size: 20px;
    line-height: 24px;
    color: $ss-c-dblue;
  }
}
