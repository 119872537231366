.strategy-collapsable-item {
  &__inner-container {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms;
    &--open {
      grid-template-rows: 1fr;
    }
  }

  &__inner-content {
    overflow: hidden;
  }

  .expand-enter {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms;
  }

  .expand-enter-active,
  .expand-exit {
    grid-template-rows: 1fr;
  }

  .expand-exit-active {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms;
  }
}
