.strategy-search__header {
  &-w {
    background-color: $ss-c-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $header-nav-zindex;
    border-bottom: $ss-border-secondary;
  }

  &-inner {
    display: flex;
    flex-direction: row;
    max-width: 1170px;
    min-width: 600px;
    margin: 0 auto;
    padding: 16px 16px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &-logo {
    width: 100%;
    max-width: 144px;
  }
}
