.strategy-search {
  &__input-w {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 420px;
  }

  &__input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: $ss-c-dblue;
    font-size: 16px;
    height: 36px;
    // padding is spec to images
    // adjust with caution
    padding: 15px;
    padding-left: 38px;
    padding-right: 16px;
    border: $ss-border-secondary;
    border-radius: $ss-border-radius;

    @include focus-gradient {
      border-color: $ss-c-primary;
      &::placeholder {
        opacity: 0;
      }
    }
    &::placeholder {
      transition: opacity 300ms;
      opacity: 1;
      color: $ss-c-dblue;
      font-size: 16px;
    }
  }

  &__input-icon {
    position: relative;
    right: -32px;
    width: 100%;
    max-width: 24px;
  }

  &__input-clear {
    opacity: 0;
    position: relative;
    left: -32px;
    width: 100%;
    max-width: 16px;
    transition: opacity 300ms;

    &--show {
      opacity: 1;
      cursor: pointer;
    }
  }
}
