.strategy-tooltip {
  &__icon {
    padding-left: 4px;
    vertical-align: middle;
    cursor: pointer;
  }

  &-body {
    max-width: 260px;
    padding: 8px !important;
    border-radius: $ss-border-radius;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    &.show {
      opacity: 1 !important;
    }
  }
}
