.strategy-search__item {
  &--row {
    &:nth-child(even) {
      background-color: $ss-c-white;
    }
    &:nth-child(odd) {
      background-color: $ss-c-lgray;
    }
  }
  &-display {
    font-size: 16px;
    &--fee {
      text-align: right;
    }

    &--name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 20px;
      min-height: 20px;
    }
  }

  h5 {
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: $ss-c-dgray;
  }

  &-col {
    display: flex;
    flex-direction: column;
    padding: 0px 12px;
    transition: all 300ms;

    &:hover {
      box-shadow: inset 1px 0px 0px 0px $ss-c-black;
    }
    &--expand {
      box-shadow: inset 1px 0px 0px 0px $ss-c-black;
      border-bottom: 1px solid $ss-c-lgray;
    }
  }

  &-null {
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    text-align: center;
    box-shadow: none;

    &--container {
      margin-top: 64px;
      background: $ss-c-white;
      margin-bottom: 30px;
      border: $ss-border-secondary;
      border-radius: 6px;
      padding: 64px;
    }
    &--title {
      font-size: 24px;
      line-height: 32px;
    }
    &--subtitle {
      font-size: 16px;
      line-height: 24px;
      padding-top: 12px;
    }
  }

  &-details {
    cursor: pointer;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px;
    align-items: center;
  }

  &-additional {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0 16px;
    &--expand {
      display: flex;
    }
  }

  &-table {
    font-size: 16px;
    line-height: 24px;
    &--row {
      max-height: 40px;
      padding-bottom: 20px;
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &--item {
      margin-top: 16px;
    }
    &--custodian {
      width: 55%;
    }
    &--abp {
      flex-grow: 1;
    }
  }

  &-list {
    text-align: left;
    color: #22263f;
    span {
      line-height: 24px;
      height: 24px;
      font-size: 16px;
      &:empty:before {
        content: '\200b';
      }
    }
    &--item {
      span {
        display: table;
        padding: 4px 0px 4px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  &-collateral {
    color: $ss-c-primary;
    overflow-y: hidden;
    max-height: 180px;
    transition: ease 300ms;
    margin-top: 5px;
    &--expand {
      max-height: 340px;
      overflow-y: scroll;
    }
  }

  &-custodian {
    min-width: 470px;
  }

  &-expandIcon {
    cursor: pointer;
    vertical-align: middle;
    &--rotate {
      transform: rotate(180deg);
    }
    transition: transform 300ms;
  }

  &-gsexclusive {
    background: rgba(91, 208, 179, 0.4);
    color: #22263f;
    line-height: 16px;
    font-size: 12px;
    padding: 4px 8px;
    margin-left: 10px;
    flex-shrink: 0;
  }
}
