.im-analysis {
  &__container {
    margin-top: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }

  &__header {
    background: #e6ecf3;
    padding: 18px 16px;
    font-size: 15px;
    line-height: 19px;
    width: 100%;
  }

  &__label {
    width: 100%;
    display: inline-block;
    text-align: right;
    &-left {
      text-align: left;
    }
  }

  &__item {
    display: inline-block;
    width: 100%;
    text-align: left;
    &-left {
      text-align: right;
      padding-right: 25px;
    }
  }

  &__metrics {
    display: flex;
    flex-direction: column;
  }

  &__legend {
    width: 100%;
    text-align: right;
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
  }

  &__box {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  &__menu {
    &-button {
      font-size: 14px;
      line-height: 22px;
      font-size: 14px;
      margin-right: 28px;
      margin-left: -5px;
      color: '#22263F';
      :hover {
        color: '#22263F';
      }
    }
  }

  &__charts {
    margin-top: 20px;
    margin-left: 20px;
  }

  &__donut {
    display: inline-block;
    &:first-child {
      margin-right: 25px;
    }
  }

  &__summary {
    display: inline-block;
    width: 100%;
    text-align: left;
    &-header {
      margin-bottom: 28px;
      font-size: 24px;
      line-height: 30px;
      &--alarm {
        font-size: 18px;
        line-height: 26px;
        span {
          display: inline-block;
          position: relative;
          top: -6px;
          margin-left: 18px;
        }
      }
    }

    &-source {
      margin-top: 28px;
      margin-left: 14px;
      font-size: 10px;
      line-height: 22px;
    }
  }

  &__notification {
    display: inline-block;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    &-title {
      margin-top: 0px;
    }
    &-message {
      margin-top: 12px;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 80px;
      span::before {
        content: '\A';
        white-space: pre;
      }
    }
  }
}
