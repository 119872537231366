.modal-im__content {
  color: #22263f;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: $c-white-smoke;
  min-width: 120px;
  min-height: 120px;
  border-radius: 2px;
  padding: 50px 0px 50px 0px;
}
