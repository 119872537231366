.strategy-search__tile {
  &-title-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 18px;
    justify-content: left;
    line-height: 20px;
    height: 40px;
  }
  &-title-text {
    @include hover-slide {
      color: $ss-c-primary;
    }
  }
}
