.im-portfolio {
  &__body-styles {
    background-color: #e6ecf3;
  }

  &__main {
    &-w {
      font-family: $font-main;
      padding-top: 25px;
      min-height: 100%;
      &:last-child {
        margin-bottom: 160px;
      }
    }
  }

  &__content {
    min-height: 600px;
  }

  &__header {
    font-size: 30px;
    line-height: 36px;
    &-w {
      width: 100%;
    }
  }

  &__subheader {
    font-size: 16px;
    line-height: 24px;
  }
  &__name {
    font-size: 18px;
  }

  &__input {
    font-size: 30px;
    line-height: 36px;

    &-field {
      position: absolute;
      left: 0;
      width: 100%;
      margin-top: 5px;
      margin-left: -5px;
      background: transparent;
      border-width: 0px;
      color: #22263f;
    }

    &-wrapper {
      position: relative;
    }

    &-expander {
      visibility: hidden;
      padding: 0px 5px;
    }
  }

  &__sum {
    text-align: right;
  }

  &__button {
    padding: 0px;
    position: relative;
    top: 0px;
    margin-right: 12px;
    &-right {
      margin: 0px 0px 0px 12px;
    }
  }

  &__footer-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
