.modal-im__spinner {
  position: relative;
  margin: 0 auto;
  animation: keyframe-rotate 2s linear infinite;
  width: 90px;
  height: 90px;
  background: rgba(34, 38, 63, 1);
  border-radius: 50%;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    border-radius: 50%;
    background: linear-gradient(
          0deg,
          rgba(245, 245, 245, 1) 50%,
          rgba(245, 245, 245, 0.9) 100%
        )
        0% 0%,
      linear-gradient(
          90deg,
          rgba(245, 245, 245, 0.9) 0%,
          rgba(245, 245, 245, 0.6) 100%
        )
        100% 0%,
      linear-gradient(
          180deg,
          rgba(245, 245, 245, 0.6) 0%,
          rgba(245, 245, 245, 0.3) 100%
        )
        100% 100%,
      linear-gradient(
          360deg,
          rgba(245, 245, 245, 0.3) 0%,
          rgba(245, 245, 245, 0) 100%
        )
        0% 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
  }

  &:after {
    background: $c-white-smoke;
    border-radius: 50%;
    top: 3%;
    bottom: 3%;
    left: 3%;
    right: 3%;
  }
}
