.messages-error {
  align-self: center;
  width: 100%;

  &__body {
    align-items: center;
    display: flex;

    &-icon {
      color: $c-orange;
      height: 16px;
      margin-right: 9px;
      width: 16px;
    }

    &-text {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
